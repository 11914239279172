import React from 'react';
import Img from '../assets/hero.png';
import Img2 from '../assets/hero2.png';

function Hero() {
    return (
        <div className="relative h-screen lg:h-full">
            <img src={Img} alt="Hero Background" className="w-full h-full object-cover" />
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="max-w-7xl mx-auto px-4 md:px-8">
                    <div className="flex flex-col justify-between mr-auto text-center md:text-left">
                        <div>
                            <h1 className="mb-4 font-serif font-medium text-white tracking-wide text-[24px] leading-[40px] md:text-[40px] md:leading-[45px] lg:text-[50px] lg:leading-[55px] uppercase">
                                Redefining Corporate Governance with <span className="text-[#006FBC]">AI</span>
                            </h1>
                            <p className="font-sans mb-6 mt-4 md:mt-8 font-light text-white text-[14px]   leading-[22px] md:text-[20px] md:leading-[28px] lg:text-[24px] lg:leading-[32px]">
                                In a dynamic regulatory environment, organizations must proactively ensure control, manage risks, and maintain operational transparency. MIPORIS provides a comprehensive, AI-powered solution to support these efforts, enhancing corporate governance, streamlining control, and empowering effective decision-making.
                            </p>
                        </div>
                        <div className="flex justify-center md:justify-start">
                            <a href='/signup'>
                                <button
                                    type="button"
                                    className="text-white bg-[#0070BC] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 md:px-6 md:py-3"
                                >
                                    Start your free trial
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
